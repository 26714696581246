import { Box, CircularProgress } from "@mui/material";

export default function LoadingPanel() {
  return (
    <Box
      sx={{
        height: (theme) =>
          `calc(100vh - ${theme.mixins.toolbar.minHeight || 56}px)`, // Boxの高さ = ウィンドウの高さ - ナビゲーションの高さ
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
