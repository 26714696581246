import { NextPage } from "next";
import Dashboard from "../components/pages/Dashboard";
import LoadingPanel from "../components/molecules/LoadingPanel";
import { useScIndexReportsSWR } from "../lib/api";
import { useAccessControl } from "../lib/accessControl";
import ascureScTitle from "../../public/serviceIcon/ascureScTitle.png";
import { SERVICE_NAMES } from "../lib/constants";

const Page: NextPage = () => {
  useAccessControl(["admin", "editor", "member"]);

  const { data, error } = useScIndexReportsSWR({});
  if (error) {
    throw new Error(error);
  }
  if (!data) {
    return <LoadingPanel />;
  }
  return (
    <Dashboard
      reports={data}
      serviceName={SERVICE_NAMES.SC}
      serviceImage={ascureScTitle}
      imageWidth={188}
      imageHeight={30}
    />
  );
};

export default Page;
