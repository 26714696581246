import _dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
_dayjs.extend(utc);
_dayjs.extend(timezone);
_dayjs.tz.setDefault("Asia/Tokyo");

export const dayjs = _dayjs;

export const isYYYYMMDD = (value: unknown): value is string => {
  return typeof value === "string" && dayjs(value, "YYYYMMDD").isValid();
};

// YYYYMMDD -> YYYY/MM/DD
export const formatYYYYMMDD = (YYYYMMDD: string) => {
  return `${YYYYMMDD.slice(0, 4)}/${YYYYMMDD.slice(4, 6)}/${YYYYMMDD.slice(
    6,
    8
  )}`;
};

/**
 * 年度の取得
 */
export const getFiscalYear = (date: string) => {
  const targetDate = dayjs(date).startOf("day");
  const currentYear = targetDate.year();
  const currentMonth = targetDate.month() + 1;
  return currentMonth < 4 ? currentYear - 1 : currentYear;
};
