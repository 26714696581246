import {
  AdminAPI,
  InvoiceDetail,
  ReportState,
  UserStatus,
} from "ascure-report-types";
import { Auth } from "aws-amplify";
import useSWR from "swr";
import { SERVICE_NAMES } from "./constants";

const _fetch = async <P>(
  path: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: P
) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return fetch(process.env.NEXT_PUBLIC_API_URL + "api/" + path, {
    method,
    mode: "cors",
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(body),
  }).then((res) => {
    return res.json();
  });
};

const _post = async <P>(path: string, body: P) => _fetch(path, "POST", body);
const _put = async <P>(path: string, body: P) => _fetch(path, "PUT", body);
const _delete = async (path: string) => _fetch(path, "DELETE");
const fetcher = async (path: string) => _fetch(path, "GET");

export const useIndexCustomerSWR = () => {
  return useSWR<AdminAPI.Customer.IndexRes>("customers", fetcher);
};
export const useShowCustomerSWR = (arg: Partial<AdminAPI.Customer.ShowArg>) => {
  return useSWR<AdminAPI.Customer.ShowRes>(
    arg.customerId ? "customers/" + arg.customerId : null,
    fetcher
  );
};

export const createCustomerStaff = async (
  arg: AdminAPI.CustomerStaff.CreateArg
): Promise<AdminAPI.CustomerStaff.CreateRes> => {
  return _post<AdminAPI.CustomerStaff.CreateArg>("customer_staffs", {
    ...arg,
  });
};

export const editCustomerStaff = async (
  arg: AdminAPI.CustomerStaff.EditArg
): Promise<AdminAPI.CustomerStaff.EditRes> => {
  return _put<AdminAPI.CustomerStaff.EditArg["params"]>(
    `customer_staffs/${arg.id}`,
    arg.params
  );
};

export const deleteCustomerStaff = async (
  arg: AdminAPI.CustomerStaff.DeleteArg
): Promise<AdminAPI.CustomerStaff.DeleteRes> => {
  return _delete(`customer_staffs/${arg.id}`);
};

export const useIndexReportsSWR = (arg: AdminAPI.Report.IndexArg) => {
  const query = arg.YYYYMMDD
    ? `?${new URLSearchParams({ YYYYMMDD: arg.YYYYMMDD }).toString()}`
    : "";
  return useSWR<AdminAPI.Report.IndexRes>("reports" + query, fetcher);
};

export const useMedrIndexReportsSWR = (arg: AdminAPI.Report.IndexArg) => {
  const query = arg.YYYYMMDD
    ? `?${new URLSearchParams({ YYYYMMDD: arg.YYYYMMDD }).toString()}`
    : "";
  return useSWR<AdminAPI.Report.IndexRes>(
    `${SERVICE_NAMES.MEDR}/reports` + query,
    fetcher
  );
};
export const useScIndexReportsSWR = (arg: AdminAPI.Report.IndexArg) => {
  const query = arg.YYYYMMDD
    ? `?${new URLSearchParams({ YYYYMMDD: arg.YYYYMMDD }).toString()}`
    : "";
  return useSWR<AdminAPI.Report.IndexRes>(
    `${SERVICE_NAMES.SC}/reports` + query,
    fetcher
  );
};

/**
 * report データの取得
 */
export const getIndexReports = (
  arg: AdminAPI.Report.IndexArg
): Promise<AdminAPI.Report.IndexRes> => {
  const params = new URLSearchParams();
  if (arg.YYYYMMDD) {
    params.append("YYYYMMDD", arg.YYYYMMDD);
  }
  if (arg.customerIds) {
    params.append("customerIds", arg.customerIds.join(","));
  }
  if (arg.serviceCode) {
    params.append("serviceCode", arg.serviceCode);
  }
  const query = params.toString() ? `?${params.toString()}` : "";
  return fetcher("reports" + query);
};

export const useShowScReportSWR = (arg: Partial<AdminAPI.Report.ShowArg>) => {
  return useSWR<AdminAPI.Report.SC.ShowRes>(
    arg.reportId ? `${SERVICE_NAMES.SC}/reports/` + arg.reportId : null, // swrはnullを引数に取るとrequestを実施しない
    fetcher
  );
};

export const useShowMedrReportSWR = (arg: Partial<AdminAPI.Report.ShowArg>) => {
  return useSWR<AdminAPI.Report.MedR.ShowRes>(
    arg.reportId ? `${SERVICE_NAMES.MEDR}/reports/` + arg.reportId : null, // swrはnullを引数に取るとrequestを実施しない
    fetcher
  );
};

export const createReportUserStatus = async (
  arg: AdminAPI.Report.CreateUserStatusArg
): Promise<AdminAPI.Report.CreateUserStatusRes> => {
  return _post<UserStatus>(
    `reports/${arg.reportId}/user_status/`,
    arg.userStatus
  );
};

export const putReportUserStatus = async (
  arg: AdminAPI.Report.PutUserStatusArg
): Promise<AdminAPI.Report.PutUserStatusRes> => {
  return _post<Omit<UserStatus, "courseId">>(
    `reports/${arg.reportId}/user_status/${arg.userStatusRowIndex}`,
    arg.userStatus
  );
};

/**
 * ascure卒煙 - report ステータスの変更 & 発行
 */
export const patchScReportState = async (
  arg: AdminAPI.Report.PatchReportStateArg
) => {
  return _post<{ state: ReportState }>(
    `${SERVICE_NAMES.SC}/reports/${arg.reportId}/state`,
    arg
  );
};

/**
 * ascureDr.受診勧奨 - report ステータスの変更 & 発行
 */
export const patchMedRReportState = async (
  arg: AdminAPI.Report.PatchReportStateArg
) => {
  return _post<{ state: ReportState }>(
    `${SERVICE_NAMES.MEDR}/reports/${arg.reportId}/state`,
    arg
  );
};

/**
 * レポート一括ダウンロードバッチを invoke する API
 */
export const invokeScReportDownloadBatch = async (
  arg: AdminAPI.ReportDownload.invokeArg
): Promise<AdminAPI.ReportDownload.invokeRes> => {
  return _post<AdminAPI.ReportDownload.invokeArg>(
    `${SERVICE_NAMES.SC}/report_download`,
    arg
  );
};

/**
 * ascure卒煙 - レポートステータスの一括変更処理を invoke する API
 */
export const invokeScReportStatusBulkChange = async (
  arg: AdminAPI.Report.PatchBulkReportStatesArg
): Promise<AdminAPI.Report.PatchBulkReportStatesRes> => {
  return _post<AdminAPI.Report.PatchBulkReportStatesArg>(
    `${SERVICE_NAMES.SC}/reports/state`,
    arg
  );
};
/**
 * ascureDr.受診勧奨 - レポートステータスの一括変更処理を invoke する API
 */
export const invokeMedRReportStatusBulkChange = async (
  arg: AdminAPI.Report.PatchBulkReportStatesArg
): Promise<AdminAPI.Report.PatchBulkReportStatesRes> => {
  return _post<AdminAPI.Report.PatchBulkReportStatesArg>(
    `${SERVICE_NAMES.MEDR}/reports/state`,
    arg
  );
};

export const useShowInvoiceSWR = (arg: Partial<AdminAPI.Invoice.ShowArg>) => {
  return useSWR<AdminAPI.Invoice.ShowRes>(
    arg.invoiceId ? "invoices/" + arg.invoiceId : null, // swrはnullを引数に取るとrequestを実施しない
    fetcher
  );
};

export const createInvoiceDetail = async (
  arg: AdminAPI.Invoice.CreateDetailArg
) => {
  return _post<{
    invoiceDetail: InvoiceDetail;
  }>(`invoices/${arg.invoiceId}/details/`, arg);
};

export const putInvoiceDetail = async (arg: AdminAPI.Invoice.PutDetailArg) => {
  return _post<{
    invoiceDetail: InvoiceDetail;
  }>(`invoices/${arg.invoiceId}/details/${arg.detailRowIndex}`, arg);
};

export const deleteInvoiceDetail = async (
  arg: AdminAPI.Invoice.DeleteDetailArg
) => {
  return _delete(`invoices/${arg.invoiceId}/details/${arg.detailRowIndex}`);
};

export const useShowDailyReportSWR = (
  arg: AdminAPI.DailyDashboard.DailyReport.ShowArg
) => {
  return useSWR<AdminAPI.DailyDashboard.DailyReport.ShowRes>(
    `daily_dashboards/daily_reports/${arg.customerId}${
      arg.YYYYMM ? "?YYYYMM=" + arg.YYYYMM : ""
    }`,
    fetcher
  );
};

export const useShowDailyMemberSWR = (
  arg: AdminAPI.DailyDashboard.DailyMember.ShowArg
) => {
  return useSWR<AdminAPI.DailyDashboard.DailyMember.ShowResFromServer>(
    `daily_dashboards/daily_members/${arg.customerId}`,
    fetcher
  );
};

export const useIndexReportEditLogsSWR = (
  arg: AdminAPI.ReportEditLog.IndexArg
) => {
  const query = arg.YYYYMMDD ? `?${new URLSearchParams(arg).toString()}` : "";
  return useSWR<AdminAPI.ReportEditLog.IndexRes>(
    "report_edit_logs" + query,
    fetcher
  );
};
export const useIndexInvoiceEditLogsSWR = (
  arg: AdminAPI.InvoiceEditLog.IndexArg
) => {
  const query = arg.YYYYMMDD ? `?${new URLSearchParams(arg).toString()}` : "";
  return useSWR<AdminAPI.InvoiceEditLog.IndexRes>(
    "invoice_edit_logs" + query,
    fetcher
  );
};

export const useIndexCustomerStaffEditLogsSWR = (
  arg: AdminAPI.CustomerStaffEditLog.IndexArg
) => {
  return useSWR<AdminAPI.CustomerStaffEditLog.IndexRes>(
    "customer_staff_edit_logs",
    fetcher
  );
};

export const useIndexDataImportEditLogsSWR = (
  arg: AdminAPI.DataImportEditLog.IndexArg
) => {
  return useSWR<AdminAPI.DataImportEditLog.IndexRes>(
    "data_import_edit_logs",
    fetcher
  );
};

/**
 * 招待コードのチェック
 */
export const checkMembershipCodes = async (
  arg: AdminAPI.Membership.MembershipCodeCheckExistenceArg
): Promise<AdminAPI.Membership.MembershipCodeCheckExistenceRes> => {
  return _post<AdminAPI.Membership.MembershipCodeCheckExistenceArg>(
    `memberships/check-existence`,
    arg
  );
};

/**
 * 受診勧奨の report データ保存
 */
export const postReportMedR = async (
  arg: AdminAPI.Report.PostArg
): Promise<AdminAPI.Report.PostRes> => {
  return _post<AdminAPI.Report.PostArg>(`medr/reports`, arg);
};

export const nextRouterQueryPostValidator = (
  id: undefined | string | string[]
): string => {
  if (typeof id !== "string") {
    throw new Error("不正なidが設定されました");
  }
  return id;
};

export const nextRouterQueryGetValidator = (
  id: undefined | string | string[]
): string | undefined => {
  if (id && typeof id !== "string") {
    throw new Error("不正なidが設定されました");
  }
  return id;
};
