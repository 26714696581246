import { useMemo } from "react";
import Box from "@mui/material/Box";

import { ReportList, ReportState } from "ascure-report-types";
import PageFrame from "../atoms/PageFrame";
import PageTitle from "../atoms/PageTitle";
import { MonthlyReportList } from "../molecules/MonthlyReportList";
import { StaticImageData } from "next/image";
import Image from "next/image";
import { Stack } from "@mui/material";
import { ServiceName } from "../../lib/constants";

type Props = {
  reports: ReportList;
  serviceName: ServiceName;
  serviceImage: StaticImageData;
  imageWidth: number;
  imageHeight: number;
};

export default function Dashboard({
  reports,
  serviceName,
  serviceImage,
  imageWidth,
  imageHeight,
}: Props) {
  const reportDays = useMemo(() => {
    const reportMap = reports.reduce<
      Record<string, Record<ReportState, number> | undefined>
    >((mergedObj, report) => {
      const reportStatusCount = mergedObj[report.YYYYMMDD];
      if (typeof reportStatusCount === "undefined") {
        const newReportStatusCount = {
          BEFORE_CHECKED: 0,
          STANDBY_FOR_AUTO_SENDING: 0,
          STANDBY_FOR_MANUAL_SENDING: 0,
          ISSUED: 0,
          DISCARDED: 0,
        };
        newReportStatusCount[report.state] = 1;
        mergedObj[report.YYYYMMDD] = newReportStatusCount;
      } else {
        reportStatusCount[report.state] += 1;
        mergedObj[report.YYYYMMDD] = reportStatusCount;
      }
      return mergedObj;
    }, {});

    const YYYYMMDDs = Object.keys(reportMap).sort(
      (a, b) => Number(b) - Number(a)
    );
    return YYYYMMDDs.map((YYYYMMDD) => ({
      YYYYMMDD,
      reportStatus: reportMap[YYYYMMDD] as Record<ReportState, number>,
    }));
  }, [reports]);

  return (
    <PageFrame>
      <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
        <PageTitle>月次レポート</PageTitle>
        <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
          <Image
            width={imageWidth}
            height={imageHeight}
            src={serviceImage}
            alt={"serviceName"}
          />
        </Box>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <MonthlyReportList reportDays={reportDays} serviceName={serviceName} />
      </Box>
    </PageFrame>
  );
}
