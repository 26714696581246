import { ReactNode } from "react";
import { Typography } from "@mui/material";

type Props = { children: ReactNode };

export default function PageTitle({ children }: Props) {
  return (
    <Typography component="h2" variant="h4">
      {children}
    </Typography>
  );
}
