import { AdminPageRole } from "ascure-report-types";
import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import { useLayoutEffect } from "react";

/**
 * 該当ページに Congnito Group (role) に基づくアクセス制御を付与
 * @param accessibleRoles アクセス可能な role リスト
 */
export const useAccessControl = (accessibleRoles: AdminPageRole[]) => {
  const router = useRouter();

  // MEMO: レンダリングされる前にチェック
  useLayoutEffect(() => {
    const control = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const roles =
        (user?.signInUserSession?.idToken?.payload?.[
          "cognito:groups"
        ] as AdminPageRole[]) || [];
      const isAccesible = roles.some((role) => accessibleRoles.includes(role));

      // FIXME: 将来的に削除
      // role が設定されていない場合は、無条件で全アクセス
      if (roles.length === 0) {
        return;
      }

      if (!isAccesible) {
        // FIXME: role ごとに redirect 先を変える
        router.replace("/");
      }
    };
    control();
  }, [router]);
};
