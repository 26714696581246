import {
  Avatar,
  Badge,
  Box,
  Chip,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { ReportState } from "ascure-report-types";
import { FC, Fragment } from "react";
import NextLink from "next/link";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { formatYYYYMMDD } from "../../lib/timeUtil";

type Props = {
  reportDays: {
    YYYYMMDD: string;
    reportStatus: Record<ReportState, number>;
  }[];
  serviceName: string;
};

/**
 * 月次レポートのリスト
 */
export const MonthlyReportList: FC<Props> = (props) => {
  const { reportDays, serviceName } = props;

  return (
    <List>
      {reportDays.map((reportDay) => {
        const beforeChecked = reportDay.reportStatus.BEFORE_CHECKED;
        const standbyCount =
          reportDay.reportStatus.STANDBY_FOR_AUTO_SENDING +
          reportDay.reportStatus.STANDBY_FOR_MANUAL_SENDING;
        return (
          <Fragment key={reportDay.YYYYMMDD}>
            <NextLink
              href={`/${serviceName}/reports/list?YYYYMMDD=${reportDay.YYYYMMDD}`}
              passHref
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Badge badgeContent={beforeChecked} color="warning">
                    <Avatar>
                      <EventNoteIcon />
                    </Avatar>
                  </Badge>
                </ListItemAvatar>

                <ListItemText
                  primary={formatYYYYMMDD(reportDay.YYYYMMDD)}
                  secondary={
                    beforeChecked
                      ? `チェック前のレポートが${beforeChecked}件あります`
                      : standbyCount
                      ? `未発行のレポートが${standbyCount}件あります`
                      : undefined
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <Tooltip title="チェック前">
                    <Chip
                      variant="filled"
                      color="warning"
                      size="small"
                      label={reportDay.reportStatus.BEFORE_CHECKED ?? 0}
                    />
                  </Tooltip>
                  <Tooltip title="送付予約中">
                    <Chip
                      variant="outlined"
                      color="warning"
                      size="small"
                      label={
                        reportDay.reportStatus.STANDBY_FOR_AUTO_SENDING ?? 0
                      }
                    />
                  </Tooltip>
                  <Tooltip title="手動送付待ち">
                    <Chip
                      variant="outlined"
                      color="warning"
                      size="small"
                      label={
                        reportDay.reportStatus.STANDBY_FOR_MANUAL_SENDING ?? 0
                      }
                    />
                  </Tooltip>
                  <Tooltip title="発行済">
                    <Chip
                      variant="filled"
                      color="success"
                      size="small"
                      label={reportDay.reportStatus.ISSUED ?? 0}
                    />
                  </Tooltip>
                  <Tooltip title="廃棄">
                    <Chip
                      variant="filled"
                      color="info"
                      size="small"
                      label={reportDay.reportStatus.DISCARDED ?? 0}
                    />
                  </Tooltip>
                </Box>
              </ListItemButton>
            </NextLink>
            <Divider component="li" />
          </Fragment>
        );
      })}
    </List>
  );
};
